@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  font-size: 14px;
}

body {
  margin: 0;
  font-family: "Ubuntu", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.app {
  height: 100vh;
  width: 100vw;
}

::-webkit-scrollbar {
  width: 9px;
  height: 9px;
}

::-webkit-scrollbar-track {
  border-radius: 8px;
  background-color: #f0f0f0;
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #e7e7e7;
}
